.avatar__container {
    text-align: center;
    position: relative;
    border: 1px solid transparent;
    overflow: hidden;
    border-radius: 20px;

    .avatar__img {
        cursor: pointer;
        height: 250px !important;
        margin: auto;
        padding: unset;
        &:hover {
            opacity: 0.5;
            transition: 0.5s;
        }
    }

    .avatar__input {
        display: none;
    }

    .avatar__upload {
        text-transform: uppercase;
        color: var(--primary);
        margin-bottom: 25px;
        font-size: 0.7rem;
        font-weight: bold;
    }

    .avatar__contain {
        img {
            object-fit: contain;
        }
    }
    .avatar__cover {
        img {
            object-fit: cover;
        }
    }
}

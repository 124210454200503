.event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
  .event__banner {
    position: absolute;
    top: -150px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 500px;
    width: 100%;
    z-index: -1;
    background-size: cover;
    @media screen and (max-width: 600px) {
      height: 450px;
    }
    &::after {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      background: linear-gradient(180deg, rgba(31, 31, 31, 0) 0%, #1f1f1f 100%),
        rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(10px);
      z-index: -1;
    }
  }
  .event__banner--mobile {
    position: absolute;
    top: -150px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 500px;
    width: calc(100vw - 8px);
    z-index: -1;
    background-size: cover;
    @media screen and (max-width: 600px) {
      height: 450px;
    }
  }
  .event__banner--clear {
    max-height: 350px;
    width: 100%;
    object-fit: cover;
    @media screen and (max-width: 600px) {
      max-height: 215px;
    }
  }
  .event__title {
    text-align: center;
    color: var(--ion-color-tertiary-contrast);
  }
  .event__info--container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    width: min(700px, 100%);
    padding-bottom: 2rem;
    .event__info {
      position: relative;
      background-color: var(--ion-color-tertiary);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.6rem;
      padding: 1.3rem;
      width: 50%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .event__info--title {
        color: var(--ion-color-primary);
        text-transform: uppercase;
        font-size: 0.8rem;
      }
      .event__info--content {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: var(--ion-color-tertiary-contrast);
        font-size: 0.7rem;
        font-weight: 700;
      }
      .event__info--cta {
        background-color: var(--ion-color-primary);
        position: absolute;
        top: 100%;
        width: 100%;
        text-align: center;
        padding: 0.6rem;
        font-size: 0.6rem;
        font-weight: 700;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        a {
          text-decoration: unset;
          background-color: unset;
          color: var(--ion-color-tertiary-contrast);
          font-size: 0.7rem;
          font-weight: 400;
          text-transform: uppercase;
          height: fit-content;
          &:hover {
            text-decoration: underline;
          }
        }
        span {
          color: var(--ion-color-tertiary);
          text-transform: uppercase;
        }
      }
    }
  }
  .event__tags {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }
  .event__desc {
    color: var(--ion-color-tertiary-contrast);
  }
  .event__discussion {
    width: 100%;
    background-color: var(--grey-dark);
    border-radius: var(--border-radius-10);
    box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.3) inset;
    backdrop-filter: blur(50px);
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    padding: 1.3rem;
    .event__discussion--title {
      color: var(--ion-color-primary);
      font-size: 0.8rem;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      padding-bottom: 10px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--ion-color-primary);
      }
    }
    .event__discussion--writeComment {
      background-color: rgba(0, 0, 0, 0.6);
      padding: 0.9rem;
      box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.3) inset;
      backdrop-filter: blur(50px);
      border-radius: var(--border-radius-10);
      // margin-top: 10px;
      display: grid;
      img {
        width: 25px;
        height: 25px;
        object-fit: cover;
        border-radius: 50%;
      }
      Ion-item {
        &::part(native) {
          background: unset;
        }
        .discussion__input {
          resize: none;
          background-color: unset;
          width: 100%;
          margin: 10px 1px;
          padding: 1px;
          color: var(--ion-color-tertiary-contrast);
        }
      }
      button {
        justify-self: end;
        width: fit-content;
        font-size: 0.7rem;
      }
    }
    .message__container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .message {
        background-color: rgba(0, 0, 0, 0.3);
        padding: 0.9rem;
        box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.3) inset;
        backdrop-filter: blur(50px);
        border-radius: var(--border-radius-10);
        // margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .message__info {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            width: 25px;
            height: 25px;
            object-fit: cover;
            border-radius: 50%;
          }
          .message__name {
            color: var(--ion-color-primary);
          }
        }
      }
      .message__content {
        background-color: rgba(31, 31, 31, 0);
        color: white;
        font-size: 12px;
        font-weight: 400;
      }
      .message__reply {
        cursor: pointer;
        float: right;
        margin-top: 10px;
      }
      .message__edit {
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: end;
        height: 30px;
        button {
          font-size: 0.7rem;
        }
      }
    }
    .message__actions {
      display: block;
      .message__actions--left {
        float: left;
        height: 30px;
      }
      .message__actions--right {
        float: right;
        display: flex;
        align-items: center;
        gap: 5px;
        justify-self: end;
        height: 30px;
        .message__actions--button {
          color: var(--ion-color-tertiary-contrast);
          background-color: unset;
          padding: 3px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .message__replyMessage {
      padding-left: 50px;
      @media screen and (max-width: 600px) {
        padding-left: 20px;
      }
    }
  }
  .event__actions {
    background-color: var(--ion-color-primary);
    width: min(100%, 400px);
    display: flex;
    justify-content: center;
    gap: 10px;
    border-radius: 80px;
    padding: 1.3rem;
    position: sticky;
    bottom: 50px;
    .event__cta {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.2);
      font-size: 0.8rem;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--ion-color-tertiary-contrast);
      padding: 13px;
      width: 100%;
    }
  }
  add-to-calendar-button.atcb-light {
    --btn-underline: none;
  }
  &.ionic {
    gap: 1rem;
    .event__content {
      width: calc(100% - 2rem);
      padding: 1rem;
      border-radius: var(--border-radius-10);
      background-color: var(--grey-dark);
      box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.3) inset;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .event__info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .event__title {
          font-size: 1.2rem;
          text-align: start;
        }
      }
      .event__desc {
        font-size: 0.8rem;
      }
      .event__info--container {
        .event__info {
          padding: 1rem;
          .event__info--content {
            font-weight: 400;
          }
        }
      }
      .event__discussion {
        backdrop-filter: unset;
        box-shadow: unset;
        padding: unset;
        width: 100%;
      }
    }
    .event__actions {
      bottom: unset;
      padding: 0.5rem;
      width: calc(100% - 2rem);
      margin-bottom: 1rem;
      .event__cta {
        padding: 0.7rem;
      }
    }
    .event__discussion {
      width: calc(100% - 2rem);
      padding: 1rem;
      .discussion__input {
        font-size: 12px;
        color: var(--ion-color-tertiary-contrast);
      }
    }
  }
}

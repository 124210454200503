.profile {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  gap: 1rem;
  &.ionic {
    display: flex;
    flex-direction: column;
  }
}

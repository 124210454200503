.eventCard {
  background-color: var(--ion-color-secondary);
  padding: 1.2rem 0.6rem;
  border-radius: var(--border-radius);
  display: grid;
  grid-template-columns: 100px 1fr 0.25fr;
  grid-template-areas: "image desc status" "image desc ." "image desc .";
  align-items: center;
  gap: 10px;
  .eventCard__left {
    grid-area: image;
    width: 100px;
    max-height: 100px;
    position: relative;
    transition: all 0.15s;
    &:hover {
      transform: scale(1.05);
    }
    .eventCard__image {
      height: 100%;
      height: 100px;
      object-fit: cover;
      border-radius: var(--border-radius-10);
      cursor: pointer;
    }
    .eventCard__date--container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--ion-color-primary);
      display: flex;
      justify-content: center;
      border-radius: var(--border-radius-10);
      .eventCard__date {
        color: var(--ion-color-tertiary);
        font-size: 7px;
        font-weight: 700;
        padding: 4px;
      }
    }
  }
  .eventCard__desc {
    grid-area: desc;
    display: grid;
    gap: 10px;
    width: 100%;
    .eventCard__title {
      color: var(--ion-color-tertiary-contrast);
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      &:hover {
        text-decoration: underline;
      }
    }
    .eventCard__place {
      display: flex;
      align-items: center;
      gap: 3px;
      .eventCard__place--icon {
        width: 12px;
        height: 12px;
      }
      .eventCard__place--text {
        font-size: 0.7rem;
        font-weight: 700;
        color: var(--ion-color-primary);
      }
    }
    .eventCard__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .eventCard__distance {
      font-size: 0.6rem;
      font-weight: 700;
      color: var(--ion-color-tertiary-contrast);
    }
    .eventCard__participants {
      display: flex;
      align-items: center;
      gap: 5px;
      .eventCard__participants--image {
        position: relative;
        height: 30px;
        width: 100%;
        max-width: 90px;
        img {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          object-fit: cover;
          position: absolute;
          top: 0;
        }
      }
      .eventCard__participants--text {
        font-size: 0.6rem;
        font-weight: 700;
        color: var(--ion-color-tertiary-contrast);
      }
    }
  }
  .eventCard__status {
    grid-area: status;

    display: flex;
    align-items: center;
    .text {
      font-size: 10px;
    }
    .circle {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 10px;
    }
    .green {
      background-color: green;
    }
    .red {
      background-color: red;
    }
    .yellow {
      background-color: yellow;
    }
  }
}
Ion-card {
  &.eventCard__container {
    background-color: unset;
    border-radius: var(--border-radius);
  }
}

.featuredEvent {
  position: relative;
  grid-column: 1 / 8;
  @media screen and (max-width: 1500px) {
    grid-column: 1 / 7;
  }
  @media screen and (max-width: 1024px) {
    grid-column: 1 / end;
  }

  .featuredEvent__desc {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    padding: 1.75rem 3.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.65rem;
    @media screen and (max-width: 600px) {
      height: 100%;
      justify-content: space-between;
    }
    &::after {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.8) 100%
        ),
        rgba(255, 255, 255, 0.01);
      backdrop-filter: blur(13.192140579223633px);
      z-index: 0;
      @media screen and (max-width: 600px) {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
          ),
          rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(0.192140579223633px);
      }
    }
  }
}
.featuredEvent__image {
  border-radius: var(--border-radius);
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.featuredEvent__title {
  font-size: 3rem;
  text-transform: uppercase;
  z-index: 2;
  width: fit-content;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (max-width: 1500px) {
    font-size: 2rem;
  }
}
.featuredEvent__date {
  font-size: 1.25rem;
  z-index: 2;
  @media screen and (max-width: 1500px) {
    font-size: 1rem;
  }
}
.featuredEvent__place {
  display: flex;
  align-items: center;
  gap: 7px;
  z-index: 2;
  .featuredEvent__place--text {
    font-size: 1.3rem;
    color: var(--ion-color-primary);
    font-weight: 700;
    @media screen and (max-width: 1500px) {
      font-size: 1rem;
    }
  }
}
.featuredEvent__tags {
  display: flex;
  gap: 0.8rem 1.7rem;
  flex-wrap: wrap;
  z-index: 2;
  @media screen and (max-width: 600px) {
    gap: 0.8rem;
  }
}

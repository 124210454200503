.login {
  .login__container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    .login__form {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
    .login__title {
      color: var(--ion-color-primary);
      font-size: 2rem;
      font-weight: 900;
      text-transform: uppercase;
      text-align: center;
    }
    .login__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.3rem;
      .login__content--desc {
        color: var(--ion-color-tertiary-contrast);
        font-size: 0.8rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        &.grey {
          color: var(--ion-color-medium);
        }
      }

      .nickname--error {
        border-style: solid;
        border-color: red;
      }
      .nickname--error--message {
        color: white;
      }
    }
    .login__socials {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.3rem;
      .login__socials--desc {
        color: var(--ion-color-medium);
        font-size: 0.7rem;
        text-transform: uppercase;
        text-align: center;
      }
      .login__socials--cta {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        .socials__button {
          border-radius: var(--border-radius);
          background-color: var(--socials-button-background);
          padding: 0.7rem;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .login__buttons {
      display: flex;
      flex-direction: column;
      gap: 1.3rem;
    }
  }
}

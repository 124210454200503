.donations {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  background-color: var(--grey-dark);
  padding: 1.3rem;
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.3) inset;
  backdrop-filter: blur(50px);
  grid-column: 6 / end;
  @media screen and (max-width: 1024px) {
    grid-column: 1 / end;
  }
  .donations__content {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    .donations__transaction {
      .donations__top {
        display: flex;
        justify-content: space-between;

        .donations__title {
          color: var(--ion-color-tertiary-contrast);
          font-size: 1rem;
          font-weight: 700;
        }
      }
      .donations__date {
        color: var(--ion-color-light);
        font-size: 0.8rem;
      }
    }
  }
}

body {
  --ion-color-primary: #ff8f00;
  --ion-color-primary-rgb: 255, 143, 0;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e07e00;
  --ion-color-primary-tint: #ff9a1a;

  --ion-color-secondary: #dadada;
  --ion-color-secondary-rgb: 218, 218, 218;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #c0c0c0;
  --ion-color-secondary-tint: #dedede;

  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-50: #ffffff50;
  --ion-color-tertiary-rgb: 0, 0, 0;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #000000;
  --ion-color-tertiary-tint: #1a1a1a;

  --ion-color-success: #14ff00;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #e30505;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #c6c6c6;
  --ion-color-medium-rgb: 198, 198, 198;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #aeaeae;
  --ion-color-medium-tint: #cccccc;

  --ion-color-light: #1a1919;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color-desktop: #efefef;
  // --ion-background-color-rgb: rgb(239, 239, 239);
  --ion-background-color: #fff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-font-family: "Inter", sans-serif;

  --border-radius: 20px;
  --border-radius-10: 10px;
  --border-radius-40: 40px;
  --grey-light: #ccc;
  --grey-dark: #e4e4e4;
  --button-padding: 10px;
  --socials-button-background: #29292980;
  --leadhouse: #7776f6;
  --progress-green: #14ff00;
  --progress-yellow: #ffc700;
  --progress-red: #ff0000;

  --section-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3) inset;

  --ion-modal-background: #ececec;
  --ion-backdrop-opacity: 0.5;
  // --background: rgba(0, 0, 0, 0):
}

body.dark {
  --ion-color-primary: #ff8f00;
  --ion-color-primary-rgb: 255, 143, 0;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e07e00;
  --ion-color-primary-tint: #ff9a1a;

  --ion-color-secondary: #212121;
  --ion-color-secondary-rgb: 33, 33, 33;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1d1d1d;
  --ion-color-secondary-tint: #373737;

  --ion-color-tertiary: #000000;
  --ion-color-tertiary-50: #00000050;
  --ion-color-tertiary-rgb: 0, 0, 0;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #000000;
  --ion-color-tertiary-tint: #1a1a1a;

  --ion-color-success: #14ff00;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #e30505;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #585858;
  --ion-color-medium-rgb: 88, 88, 88;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #4d4d4d;
  --ion-color-medium-tint: #696969;

  --ion-color-light: #bfbfbf;
  --ion-color-light-rgb: 191, 191, 191;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #a8a8a8;
  --ion-color-light-tint: #c5c5c5;
  --ion-toolbar-background: #000;

  --ion-background-color-desktop: #1f1f1f;
  // --ion-background-color-rgb: rgb(31, 31, 31);
  --ion-background-color: #000;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-modal-background: #131313;
  // --ion-modal-background: linear-gradient(
  //     0deg,
  //     rgba(0, 0, 0, 0.3) 0%,
  //     rgba(0, 0, 0, 0.3) 100%
  //   ),
  //   #1b1b1b;
  --grey-dark: #1a1919;
  --grey-light: #333;
  --section-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.3) inset;
  --border-radius: 20px;
  --ion-item-background: var(--ion-color-light);
  --ion-backdrop-opacity: 0.5;
  // --background: rgba(0, 0, 0, 0):
}

.profileInfo {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  background-color: var(--grey-dark);
  padding: 1.3rem;
  border-radius: var(--border-radius);
  box-shadow: var(--section-shadow);
  backdrop-filter: blur(50px);
  grid-column: 1 / end;
  .profileInfo__content {
    display: flex;
    align-items: center;
    gap: 10px;

    .profileInfo__photo {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 50%;
    }
    .profileInfo__name {
      color: var(--ion-color-primary);
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
  &.ionic {
    background-color: unset;
    backdrop-filter: unset;
    box-shadow: unset;
    .profileInfo__content {
      flex-direction: column;
      .profileInfo__photo {
        width: 160px;
        height: 160px;
      }
      .profileInfo__name {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

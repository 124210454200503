* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow: auto;
}

.root {
  position: relative;
}

Ion-menu {
  --background: var(--ion-color-tertiary);
}

// Custom Scrollbar
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffb200;
}
.ionic {
  ::-webkit-scrollbar-track {
    box-shadow: initial;
    -webkit-box-shadow: initial;
    border-radius: initial;
  }

  ::-webkit-scrollbar {
    width: initial;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: initial;
    border-radius: initial;
    -webkit-box-shadow: initial;
    background-color: initial;
  }
}

.centered__parent {
  display: flex;
  justify-content: center;
}

ion-footer {
  ion-toolbar {
    ion-title {
      text-align: center;
    }
  }
}

// Shared compoennets
.app__header {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ionic {
  .app__header {
    img {
      max-width: 30px;
    }
  }
}

.app__header--spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    align-items: start;
    // align-items: start;
    // flex-direction: column;
    // gap: 10px;
    & > * {
      &:nth-child(2) {
        align-self: end;
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .button__primary {
    padding: 4px 8px;
  }
}

// Input
input {
  background-color: var(--ion-color-medium);
  border-radius: var(--border-radius-10);
  border: unset;
  padding: 0.7rem 1.4rem;
  color: var(--ion-color-tertiary-contrast) !important;
  &:focus-visible {
    outline: unset;
  }
  &::placeholder {
    color: var(--ion-color-light);
    font-size: 0.8rem;
  }
}

// IonTextarea
Ion-textarea {
  --highlight-color: rgba(0, 0, 0, 0) !important;
  --padding-bottom: 0 !important;
  --padding-top: 0 !important;
}

// IonToggle
Ion-toggle {
  --track-background: #bfbfbf;
  --track-background-checked: var(--ion-color-primary);
  // --handle-background: var(--ion-color-tertiary-contrast);
  --handle-background-checked: #fff;
}

// IonSelect
Ion-select {
  min-height: unset;
  z-index: unset;
  &::part(text) {
    color: var(--ion-color-primary);
    font-size: 0.8rem;
  }
}
Ion-popover.select-popover {
  &::part(content) {
    min-width: unset;
    width: 200px;
    border-radius: var(--border-radius);
  }
}
Ion-select-popover {
  Ion-list {
    background-color: var(--grey-light) !important;
    width: 200px;
    Ion-radio-group {
      Ion-item {
        --min-height: unset;
        Ion-radio {
          color: var(--ion-color-tertiary-contrast);
          font-size: 0.7rem;
        }
        &::part(native) {
          background-color: var(--grey-light);
        }
      }
    }
  }
}

Ion-alert {
  .alert-wrapper {
    border-radius: var(--border-radius) !important;
    background: var(--grey-dark) !important;
    .alert-checkbox-group {
      button.alert-checkbox-button {
        min-height: 34px;
      }
    }
  }
  .alert-title {
    color: var(--ion-color-tertiary-contrast) !important;
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    text-transform: uppercase;
  }
  .alert-message {
    color: var(--ion-color-light) !important;
  }
  .alert-checkbox-label {
    color: var(--ion-color-light) !important;
    font-size: 0.7rem !important;
    // padding: 5px 26px 5px 53px !important;
  }
  .alert-button {
    .alert-button-inner {
      font-weight: 700;
    }
  }
}

.ionSelect {
  background-color: var(--ion-color-medium);
  border-radius: var(--border-radius-10);
  padding: 0.7rem 1.4rem;
  &::part(label) {
    color: var(--ion-color-light);
    text-transform: uppercase;
    font-size: 12px;
  }
  &::part(text) {
    font-size: 1rem;
  }
}

body {
  Ion-list.optionsMenu {
    background-color: unset;
    padding: 0;
    Ion-item {
      --inner-padding-start: 0;
      --inner-padding-end: 0;
      --inner-border-width: 0px;
      &:hover {
        background-color: unset !important;
        --background-hover: unset !important;
      }
      &::part(native) {
        background-color: unset;
        padding: 0;
      }
      &::before {
        position: absolute;
        content: url("../../assets/icons/kebab.svg");

        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // filter: invert(100%) sepia(0%) saturate(7496%) hue-rotate(193deg)
        // brightness(101%) contrast(101%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      Ion-select {
        &::part(container) {
          visibility: hidden;
          width: 21px;
          height: 21px;
        }
      }
    }
  }
  &.dark {
    Ion-list.optionsMenu {
      Ion-item {
        &::before {
          filter: invert(100%) sepia(0%) saturate(7496%) hue-rotate(193deg) brightness(101%) contrast(101%);
        }
      }
    }
  }
}

button {
  &:focus-visible {
    outline: unset;
  }
}

// input image
.app__inputFile {
  position: relative;
  .MuiAvatar-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: var(--ion-color-medium);
    padding: 4rem;
    border-radius: var(--border-radius-10);
    cursor: pointer;
    svg {
      color: var(--ion-color-light);
      height: 36px;
      width: 36px;
    }
    @media screen and (max-width: 600) {
      padding: 2rem;
    }
  }
  .app__inputFile--text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--ion-color-light);
    text-transform: uppercase;
    font-size: 0.7rem;
    width: 100%;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    width: 100%;
    height: 100%;
    &:hover {
      text-decoration: underline;
    }
  }
}

// DatePicker
.datePicker {
  width: 100%;
}
.react-datepicker__month {
  margin: unset !important;
}

// Editor
.rdw-editor-toolbar {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-bottom: unset !important;
}
.custom-editor-border {
  background: var(--ion-color-medium);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--ion-color-tertiary-contrast);
}
.DraftEditor-root {
  max-height: 400px;
}
.DraftEditor-editorContainer {
  padding: 0 10px 1px;
}

// IonToast
Ion-toast {
  --border-radius: 4px;
  position: fixed;
  --background: var(--grey-dark);
  &::part(container) {
    border-radius: 4px;
    & > * {
      padding: 10px;
    }
  }
  &::part(message) {
    color: var(--ion-color-tertiary-contrast);
    text-transform: uppercase;
  }
  &::part(icon) {
    width: 67px;
    height: 100%;
    margin: 0;
    contain: unset;
  }
}

// IonModal
Ion-modal {
  --max-height: calc(100% - (var(--ion-safe-area-top) + 10px));
  --height: auto;
  --background: var(--ion-modal-background);
  .ion-page {
    padding: 25px;
  }
  &::part(backdrop) {
    --backdrop-opacity: 0.5;
  }
  &::part(content) {
    overflow-y: scroll;
  }
}

// IonRadio
Ion-radio {
  &::part(container) {
    background: var(--ion-color-light);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid var(--ion-color-medium);
  }
  &.radio-checked {
    &::part(container) {
      height: 24px;
      width: 24px;
      background: var(--ion-color-primary);
      border-radius: 50%;
      border: 1px solid var(--ion-color-primary-shade);
    }
    &::part(mark) {
      border-color: #000;
      margin-bottom: 2px;
    }
  }
}

// IonItem
Ion-item {
  --background: var(--ion-background-color);
  --color: var(--ion-color-tertiary-contrast);
  --inner-border-width: 0px;
}

// IonMenu
Ion-menu {
  Ion-header {
    Ion-toolbar {
      --background: var(--ion-color-medium);
    }
  }
}

// IonSegment
Ion-segment.ios {
  --background: var(--grey-light);
}
Ion-segment-button {
  &::part(indicator-background) {
    background: var(--ion-color-primary);
  }
  &::part(native) {
    color: var(--ion-color-tertiary-contrast);
  }
  Ion-label {
    text-transform: uppercase;
    font-weight: 700;
  }
}
.segment-button-checked::part(native) {
  color: #000;
}
ion-segment-button.md {
  --indicator-height: 3px;
}
.segment-button-checked.md::part(native) {
  color: var(--ion-color-tertiary-contrast);
}

// IonContent
Ion-content {
  &.page-content {
    --padding-bottom: 32px;
  }
}

.badges {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  background-color: var(--ion-color-tertiary);
  padding: 1.3rem;
  border-radius: var(--border-radius);
  overflow: hidden;
  grid-column: 1 / end;
  .badges__content {
    display: flex;
    .badges__icon--container {
      position: relative;
      height: 180px;
      width: 130px;
      .badges__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.animation {
          z-index: 1;
        }
      }
      .badges__goldAnimation {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 2;
        height: 70px;
        width: 70px;
      }
    }
  }
  .badges__more {
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    width: fit-content;
  }
  .badges__text {
    color: var(--ion-color-tertiary-contrast);
  }
  &.ionic {
    background-color: var(--grey-dark);
    box-shadow: var(--section-shadow);
    backdrop-filter: blur(50px);
    padding: unset;
    gap: unset;
    .app__header {
      padding: 1.3rem 1.3rem 0 1.3rem;
    }
    .badges__content--container {
      padding: 1.3rem;
      margin: 0 2px;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

.badgesModal__container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .badgesModal__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .badgesModal__icon--container {
      position: relative;
      height: 180px;
      .badgesModal__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 130px;
        margin: auto;
        &.animation {
          z-index: 2;
        }
      }
    }
    .badgesModal__text {
      columns: var(--ion-color-tertiary-contrast);
      text-transform: capitalize;
    }
  }
}
.badges__container {
  position: relative;
  width: fit-content;
  margin: auto;
  .badges__center {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 25px;
    transform: translate(-50%, 0);
    height: 78px;
    width: 78px;
  }
}

.eventList {
  background-color: var(--ion-color-tertiary);
  border-radius: var(--border-radius);
  padding: 1.7rem;
  grid-column: 8 / end;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  @media screen and (max-width: 1500px) {
    grid-column: 7 / end;
  }
  @media screen and (max-width: 1024px) {
    grid-column: 1 / end;
  }
  .app__header {
    .app__title--white {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .eventList__content {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
  }
  .eventList__viewall {
    color: var(--ion-color-tertiary-contrast);
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    width: fit-content;
    &:hover {
      text-decoration: underline;
    }
  }
  &.ionic {
    background-color: var(--grey-dark);
    box-shadow: var(--section-shadow);
    backdrop-filter: blur(50px);
    display: grid;
    .eventList__viewall {
      justify-self: end;
    }
  }
}

.donate {
  background-color: var(--ion-color-tertiary);
  padding: 1.3rem;
  grid-column: 6 / end;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.3rem;
  @media screen and (max-width: 1024px) {
    grid-column: 1 / end;
  }
  .donate__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h4 {
      text-align: center;
    }
  }
  .donate__image--container {
    height: 100%;
    .donate__image {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  &.ionic {
    background-color: var(--ion-color-primary);
    justify-content: start;
    gap: 5px;
  }
}

.donateModal {
  .donateModal__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    .option {
      background-color: var(--ion-color-secondary);
      display: flex;
      gap: 10px;
      justify-content: space-between;
      padding: 16px 22px;
      border-radius: var(--border-radius-10);
      .option__left {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .option__title {
          color: var(--ion-color-tertiary-contrast);
          font-size: 0.8rem;
          font-weight: 700;
        }
        .option__subtitle {
          color: var(--ion-color-light);
          font-size: 0.6rem;
        }
      }
    }
  }
}

.eventPortal {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  background-color: var(--grey-dark);
  padding: 1.3rem;
  border-radius: var(--border-radius);
  box-shadow: var(--section-shadow);
  backdrop-filter: blur(50px);
  grid-column: 1 / 6;
  grid-row: span 2;
  @media screen and (max-width: 1024px) {
    grid-column: 1 / end;
  }
  .app__title--orange {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .eventPortal__section {
    display: flex;
    align-items: center;
    gap: 10px;
    .eventPortal__title {
      width: 50%;
      color: var(--ion-color-medium);
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      border-bottom: 2px solid var(--ion-color-medium);
      cursor: pointer;
    }
  }
  .eventPortal__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.3rem;
    @media screen and (max-width: 1600px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}

html {
  font-size: 17px;
  font-family: "Inter", sans-serif;
  @media screen and (max-width: 600px) {
    font-size: 15px;
  }
}

// Components
.app__title--orange {
  color: var(--ion-color-primary);
  font-size: 1.9rem;
  font-weight: 900;
  text-transform: uppercase;
  @media screen and (max-width: 600px) {
    font-size: 1.7rem;
  }
}
.app__title--white {
  color: var(--ion-color-tertiary-contrast);
  font-size: 1.9rem;
  font-weight: 900;
  text-transform: uppercase;
}
.app__title--black {
  color: #000;
  font-size: 1.9rem;
  font-weight: 900;
  text-transform: uppercase;
}

.ionic {
  .app__title--orange {
    font-size: 1.25rem;
  }
  .app__title--black {
    font-size: 1.25rem;
  }
  .app__title--white {
    font-size: 1.25rem;
  }
}

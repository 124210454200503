.settings {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.3rem;
    background-color: var(--ion-color-tertiary);
    padding: 1.3rem;
    border-radius: var(--border-radius);
    box-shadow: var(--section-shadow);
    backdrop-filter: blur(50px);
    grid-column: 6 / end;
    @media screen and (max-width: 1024px) {
        grid-column: 1 / end;
    }
    .settings__content {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        .settings__setting {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .settings__info {
                display: flex;
                align-items: center;
                gap: 0.6rem;
                .settings__name {
                    color: var(--ion-color-light);
                    font-size: 1.2rem;
                }
            }
        }
    }
    &.ionic {
        background-color: var(--grey-dark);
        .settings__content {
            Ion-item {
                --background: var(--grey-dark);
                &::part(native) {
                    padding-left: 0;
                }
                Ion-img {
                    padding-right: 5px;
                }
            }
            .settings__setting {
                Ion-label {
                    display: flex;
                    color: var(--ion-color-light);
                    align-items: center;
                    gap: 10px;
                    font-size: 1.1rem;
                }
            }
        }
    }
}

.referring {
  background-color: var(--ion-color-primary);
  padding: 1.7rem;
  grid-column: 1 / 6;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.3rem;
  @media screen and (max-width: 1024px) {
    grid-column: 1 / end;
  }
  .referring__desc {
    color: #000;
    font-size: 0.8rem;
    font-weight: 500;
  }
  .referring__code--container {
    background-color: var(--ion-color-tertiary);
    padding: 0.8rem 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    p {
      color: var(--ion-color-tertiary-contrast);
      font-size: 0.8rem;
      font-weight: 400;
      text-transform: uppercase;
      .referring__code {
        color: var(--ion-color-primary);
        font-weight: 700;
      }
    }
    img {
      cursor: pointer;
      height: 32px;
    }
  }
}

.events {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  .events__noresult {
    color: var(--ion-color-tertiary-contrast);
  }
  .events__featuredEvent {
    position: relative;
    .featuredEvent__image {
      max-height: 500px;
    }
    .events__featuredEvent--info {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      max-height: 500px;
      width: clamp(300px, 30%, 500px);
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      padding: 1.75rem 3.5rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 0.65rem;
      @media screen and (max-width: 1024px) {
        padding: 1rem 2rem;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        background: linear-gradient(
            45deg,
            rgba(255, 143, 0, 0.5) 0%,
            rgba(255, 143, 0, 0) 100%
          ),
          linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%),
          rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(13.192140579223633px);
      }
    }
  }
  .events__all {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    background-color: var(--grey-dark);
    border-radius: var(--border-radius);
    padding: 1.7rem;
    .events__filters {
      display: flex;
      align-items: center;
      gap: 0.7rem;
      @media screen and (max-width: 850px) {
        flex-direction: column;
        align-items: end;
      }
      .filter {
        background-color: var(--grey-light);
        display: flex;
        align-items: center;
        padding: 5px 12px;
        border-radius: 8px;
        gap: 5px;
        cursor: pointer;
        .filter__select {
          &::part(container) {
            max-height: 14px;
          }
        }
        span {
          white-space: nowrap;
          font-size: 0.7rem;
          color: var(--ion-color-primary);
          display: flex;
          gap: 5px;
        }
      }
    }
    .events__content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.3rem;
      @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
  }
  &.ionic {
    .events__all {
      background-color: var(--grey-dark);
      box-shadow: var(--section-shadow);
      backdrop-filter: blur(50px);
    }
    .events__filters {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  }
}

.poll {
  background-color: var(--ion-color-tertiary);
  border-radius: var(--border-radius);
  grid-column: 1 / 5;
  @media screen and (max-width: 1024px) {
    grid-column: 1 / end;
  }
  .poll__container {
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
    padding: 1.7rem;
    .poll__question {
      color: var(--ion-color-light);
    }
    .poll__options {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
    }
  }
  &.ionic {
    box-shadow: var(--section-shadow);
    backdrop-filter: blur(50px);
    // .poll__container {
    //   gap: 1.25rem;
    //   .poll__options {
    //     flex-direction: row;
    //     justify-content: space-between;
    //     button {
    //       width: 100%;
    //     }
    //   }
    // }
  }
}

.containerCustomModal {
  width: calc(100vw - 8px);
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.customModal {
  width: min(80vw, 540px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--ion-color-tertiary);
  border-radius: var(--border-radius);
  transform-origin: top left;
  z-index: 35;
  .customModal__container {
    position: relative;
    padding: 100px;
    @media screen and (max-width: 600px) {
      padding: 50px 25px;
    }
  }
}
.customModal__background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 30;
}

// React Modal
.ReactModal__Body--open {
  overflow-x: hidden;
}
.ReactModal__Overlay {
  z-index: 10;
  background-color: var(--ion-color-tertiary-50) !important;
  backdrop-filter: blur(7px);
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  width: min(80vw, 540px);
  height: fit-content;
  max-height: 80%;
  border: unset !important;
  border-radius: var(--border-radius) !important;
  z-index: 30;
  padding: 100px !important;
  background-color: var(--ion-color-tertiary) !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 600px) {
    padding: 50px 25px !important;
  }
  .modal__title {
    color: var(--ion-color-primary);
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
  }
  .modal__close {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 30px;
    right: 30px;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      top: 15px;
      right: 15px;
    }
  }
}

// IonModal
Ion-modal {
  .ion-page {
    display: flex;
    gap: 20px;
    margin: 40px 0;
  }
  .modal__title {
    color: var(--ion-color-primary);
    font-size: 2.25rem;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
  }
  .modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .modal__subtitle {
    color: var(--ion-color-tertiary-contrast);
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
}

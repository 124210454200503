button:disabled {
  &:hover {
    transform: unset;
  }
}
.button__primary {
  background-color: var(--ion-color-primary);
  color: #000;
  padding: 0.6rem 1.3rem;
  border-radius: var(--border-radius-40);
  font-size: 1rem;
  line-height: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0.15s;
  text-transform: uppercase;
  // max-height: 35px;
  &:hover {
    transform: scale(1.02);
  }

  @media screen and (max-width: 1024px) {
    &:hover {
      transform: unset;
    }
  }
}
.button__secondary {
  background-color: #00000000;
  color: #000;
  padding: 0.6rem 1.3rem;
  border: 2px solid #000;
  border-radius: var(--border-radius-40);
  font-size: 1rem;
  line-height: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0.15s;
  text-transform: uppercase;
  &:hover {
    transform: scale(1.02);
  }
  @media screen and (max-width: 1024px) {
    &:hover {
      transform: unset;
    }
  }
}
.button__login {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-tertiary-contrast);
  padding: 0.6rem 1.3rem;
  border-radius: var(--border-radius-40);
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all 0.15s;
  &:hover {
    transform: scale(1.05);
  }
}
.button__text {
  background-color: unset;
  color: var(--ion-color-tertiary-contrast);
  font-size: 0.7rem;
  text-transform: uppercase;
  height: fit-content;
  &:hover {
    text-decoration: underline;
  }
}

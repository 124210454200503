.eventCreation__container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.eventCreation__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}
.eventCreation__error {
  padding: 3px;
  background-color: red;
  color: black;
  font-weight: 700;
  border-radius: 10px;
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.featuredVideo {
  position: relative;
  grid-column: 5 / end;
  @media screen and (max-width: 1024px) {
    grid-column: 1 / end;
  }
  .featuredVideo__video {
    border-radius: var(--border-radius);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .featuredVideo__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-width: 100px;
    cursor: pointer;
    .featuredVideo__text {
      text-align: center;
      color: var(--ion-color-primary);
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.tag {
  background-color: var(--ion-color-primary);
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: "";
    background-color: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.75;
  }
  &.small {
    padding: 6px 10px;
    border-radius: 8px;
    .tag__text {
      // z-index: 3;
      position: inherit;
      color: var(--ion-color-primary);
      font-size: 0.6rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    &::before {
      border-radius: 8px;
    }
  }
  &.big {
    padding: 9px 13px;
    border-radius: 8px;
    .tag__text {
      // z-index: 3;
      position: inherit;
      color: var(--ion-color-primary);
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    &::before {
      border-radius: 8px;
    }
  }
}

.mainLayout {
  background-color: var(--ion-background-color-desktop);
}
.mainLayout__container {
  max-width: 1720px;
  margin: 0 auto;
  padding-inline: 50px;
  background-color: var(--ion-background-color-desktop);

  @media screen and (max-width: 1024px) {
    padding-inline: 0;
  }
  // Page Event Glass header
  &.glassHeader {
    position: relative;
    &::before {
      position: fixed;
      content: "";
      top: 0;
      left: -5px;
      z-index: 5;
      display: flex;
      width: 110vw;
      height: 100px;
      // background: rgba(0, 0, 0, 0.01);
      box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.3) inset;
      backdrop-filter: blur(50px);
      @media screen and (max-width: 600px) {
        height: 85px;
      }
    }
    .mainLayout__header {
      background-color: unset;
      position: fixed;
      top: 0;
      z-index: 5;
    }
  }

  .mainLayout__header {
    z-index: 10;
    position: fixed;
    top: 0;
    width: min(calc(100% - 100px), 1620px);
    background-color: var(--ion-background-color-desktop);
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding: 25px 50px;
    @media screen and (max-width: 1024px) {
      width: min(100%, 1620px);
    }
    @media screen and (max-width: 600px) {
      padding-inline: 25px;
      height: 85px;
    }
    .header__logo {
      cursor: pointer;
      @media screen and (max-width: 600px) {
        max-width: 150px;
      }
    }
    .header__right {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .header__profile {
      width: 50px;
      height: 50px;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .mainLayout__children {
    min-height: 100vh;
    padding-top: 100px;
    padding-inline: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      padding-inline: 25px;
      padding-top: 85px;
    }
  }
  .mainLayout__footer {
    margin-top: 3.5rem;
    .footer__logos--container {
      background-image: url(../../assets/images/footer-bg.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      .footer__logos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3.5rem 2.5rem;
        gap: 3rem;
        & > * {
          grid-column: span 2;
          &:nth-last-child(2) {
            grid-column: 2/4;
          }
          &:last-child {
            grid-column: 4/6;
          }
        }
        @media screen and (max-width: 1450px) {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          justify-items: center;
        }
        @media screen and (max-width: 768px) {
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          & > * {
            grid-column: 1 / end !important;
          }
        }
        @media screen and (max-width: 600px) {
          padding: 1.5rem;
        }
      }
    }
    .footer__legals {
      background-color: var(--ion-color-tertiary);
      padding: 1.5rem 2.3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      @media screen and (max-width: 600px) {
        padding: 1rem 1.5rem;
      }
      .footer__legals--rights {
        color: var(--ion-color-light);
        font-size: 0.9rem;

        @media screen and (max-width: 1024px) {
          font-size: 0.7rem;
        }
      }
      .footer__legals--lh {
        font-size: 0.9rem;
        color: var(--ion-color-medium);

        @media screen and (max-width: 1024px) {
          font-size: 0.7rem;
        }
        a {
          text-decoration: none;
          color: var(--ion-color-light);
          font-weight: 700;
          position: relative;
          display: inline-block;
          &::after {
            left: 50%;
            right: 50%;
            content: "";
            position: absolute;
            border-bottom: 2px solid var(--leadhouse);
            top: 100%;
            transition: all 0.4s;
          }
          &:hover::after {
            right: 0;
            left: 0;
          }
        }
      }
      .footer__legals--terms {
        color: var(--ion-color-tertiary-contrast);
        font-weight: 700;
        font-size: 0.9rem;
        text-align: end;

        @media screen and (max-width: 1024px) {
          font-size: 0.7rem;
        }
      }
    }
  }
}

.mainLayout__hbgMenu {
  margin-inline: 5px;
}

Ion-toolbar {
  --border-width: 0 !important;
}
Ion-content {
  // &.page-content {
  //   --background: none;
  //   background: url("../../assets/images/mobile-bg.png") no-repeat center top /
  //     100%;
  // }
  // &.page-content-event {
  //   --background: none;
  //   background: url("../../assets/images/mobile-bg.png") 100% center/100%
  //     no-repeat;
  //   background-position: top;
  // }
  &.page-content-event {
    --background: var(--ion-color-tertiary);
  }
}
.hbg-menu {
  padding-inline: 5px;
}
Ion-menu {
  &.mobile-menu {
    Ion-content {
      &.darkBg {
        &::part(background) {
          background: url("../../assets/images/mobile-menu-bg-dark.png") 100% top/100% no-repeat;
        }
      }
      &::part(background) {
        background: url("../../assets/images/mobile-menu-bg.png") 100% top/100% no-repeat;
      }
      .content__container {
        padding: 75px 35px 25px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .menu__header {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          Ion-avatar {
            margin-bottom: 5px;
          }
          .menu__welcome {
            color: var(--ion-color-tertiary-contrast);
          }
          .menu__name {
            color: var(--ion-color-primary);
            font-size: 1.75rem;
          }
        }
        Ion-list {
          margin-top: 50px;
          background-color: unset;
          Ion-item {
            --background: transparent;
            .menu__item--icon {
              margin-right: 12px;
            }
          }
        }
        Ion-item.menu__item--logout {
          --background: transparent;
          .menu__item--icon {
            margin-right: 12px;
          }
        }
      }
    }
  }
}

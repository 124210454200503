.lyc {
  &.ionic {
    background-color: var(--grey-dark);
    border-radius: var(--border-radius);
    box-shadow: var(--section-shadow);
    backdrop-filter: blur(50px);
    .lyc__container {
      padding: 1.7rem;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .lyc__comeback {
        color: var(--ion-color-tertiary-contrast);
      }
      .lyc__score {
        position: relative;
        padding: 1rem;
        border-radius: var(--border-radius);
        background-color: var(--ion-color-tertiary);
        display: flex;
        flex-direction: column;
        color: var(--ion-color-tertiary-contrast);
        justify-content: center;
        align-items: center;
        .lyc__score--title {
          font-weight: 700;
          margin-bottom: 1rem;
        }
        .lyc__score--circle {
          max-height: 155px;
          .CircularProgressbar {
            .CircularProgressbar-text {
              font-size: 0.5rem;
              font-weight: 400;
            }
          }
          .score {
            position: absolute;
            font-size: 3rem;
            font-weight: 700;
            transform: rotate(90deg);
            margin-left: 90px;
          }
        }
        .lyc__score--avg {
          margin-bottom: 0.5rem;
          display: flex;
          gap: 5px;
          justify-content: center;
          align-items: center;
          img {
            max-width: 14px;
            max-height: 14px;
          }
        }
        .lyc__score--compared {
          color: var(--ion-color-light);
        }
      }
      .lyc__content {
        display: grid;
        flex-direction: column;
        gap: 10px;
        .carbonElement {
          display: flex;
          gap: 20px;
          min-height: 60px;
          .carbonElement__left {
            background-color: var(--ion-color-secondary);
            border-radius: var(--border-radius-10);
            min-width: 60px;
            position: relative;
            .carbonElement__progress {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(90deg) !important;
            }
            .carbonElement__icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: var(--ion-color-tertiary);
              border-radius: 50%;
              padding: 4px;
            }
          }
          .carbonElement__text {
            color: var(--ion-color-tertiary-contrast);
            display: flex;
            align-items: center;
            font-size: 0.9rem;
            font-weight: 700;
          }
        }
      }
      .lyc__cta {
        width: fit-content;
        align-self: end;
        font-weight: 700;
      }
    }
  }
}

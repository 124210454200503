.home {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  gap: 1rem;
}

.home.ionic {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  Ion-header {
    margin-top: 20px;
    Ion-toolbar {
      --padding-start: 1rem;
      --padding-end: 1rem;
    }
  }
  .home__menuLeft {
    gap: 10px;
    display: flex;
    align-items: center;
  }
  .home__profilePhoto {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
  }
  .home__profileInfo {
    display: flex;
    flex-direction: column;
    .home__welcome {
      font-size: 0.9rem;
      color: var(--ion-color-tertiary-contrast);
    }
    .home__profileName {
      font-size: 1.75rem;
      color: var(--ion-color-primary);
    }
  }
}

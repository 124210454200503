.carbonFootprint {
  .current-value {
    display: none;
  }
  .carbonFootprint__banner--container {
    display: flex;
    position: relative;
    img {
      width: 100%;
      // max-height: min(40vh, 400px);
      object-fit: cover;
    }
    .carbonFootprint__tag {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
    }
  }
  .carbonFootprint__swiper {
    display: flex;
    flex-direction: column-reverse;
    background-color: var(--ion-color-tertiary);
    .swiper-pagination {
      position: unset;
      background-color: var(--ion-color-tertiary);
      padding: 16px 16px 0;
      display: flex;
      .swiper-pagination-bullet {
        width: 100%;
        height: 4px;
        border-radius: 20px;
        background-color: var(--grey-light);
        opacity: unset;
        &.swiper-pagination-bullet-active {
          background-color: var(--ion-color-light);
        }
      }
    }
    .carbonFootprint__content {
      background-color: var(--grey-dark);
      backdrop-filter: blur(50px);
      border-radius: 12px;
      box-shadow: var(--section-shadow);
      position: relative;
      .carbonFootprint__question {
        color: var(--ion-color-primary);
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding: 2rem 1rem;
      }
      .carbonFootprint__information {
        position: absolute;
        top: 5px;
        right: 5px;
      }
      .carbonFootprint__answers {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: var(--ion-color-tertiary);
        border-radius: var(--border-radius-10);
        backdrop-filter: blur(50px);
        box-shadow: var(--section-shadow);
        .answer {
          background-color: var(--ion-color-secondary);
          padding: 1rem 0.5rem;
          border-radius: var(--border-radius-10);
          border: 1px solid var(--ion-color-primary);
          color: var(--ion-color-tertiary-contrast);
          text-transform: uppercase;
          font-size: 0.9rem;
          font-weight: 700;
          height: 50px;
          &:hover {
            background-color: var(--ion-color-primary);
          }
          &.confirm {
            background-color: var(--ion-color-primary);
          }
        }
      }
    }
  }
}

.plusMinus {
  background-color: var(--ion-color-secondary);
  border: 1px solid var(--ion-color-primary);
  border-radius: var(--border-radius-10);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  height: 50px;
  .plusMinus__content {
    text-align: center;
    .plusMinus__text {
      color: var(--ion-color-tertiary-contrast);
      font-size: 0.8rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    .plusMinus__quantity {
      color: var(--ion-color-primary);
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
  .plusMinus__button {
    background-color: unset;
    font-size: 1rem;
    font-weight: 700;
    width: 24px;
    height: 24px;
  }
}
.carbonInformationModal {
  color: var(--ion-color-tertiary-contrast);
}

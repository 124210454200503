.onboarding {
  z-index: 10;
  background-image: url("../../assets/images/onboarding-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  height: 100vh;
  padding: 100px;
  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 0 0 0;
    grid-template-rows: 100%;
    justify-items: center;
    gap: unset;
    padding: 50px 25px;
  }
  .onboarding__section {
    background-image: url("../../assets/images/onboarding-circles.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 400px;
    min-width: 120px;
    width: 100%;
    padding: 75px 45px;
    background-color: var(--ion-color-tertiary);
    text-align: center;
    position: relative;
    &.second {
      background-image: url("../../assets/images/onboarding-circles2.svg");
    }
    &.third {
      background-image: url("../../assets/images/onboarding-circles3.svg");
    }
    &.fourth {
      background-image: url("../../assets/images/onboarding-circles4.png");
    }
    .onboarding__text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .onboarding__text--grey {
        font-size: 1rem;
        color: var(--ion-color-light);
      }
      .onboarding__h6--white {
        font-size: 1rem;
        color: var(--ion-color-tertiary-contrast);
        font-weight: 700;
      }
    }
    .onboarding__notification {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .onboarding__notification--name {
        color: var(--ion-color-light);
        font-size: 0.9rem;
      }
    }
    .button__primary {
      text-transform: uppercase;
    }
    .onboarding__back {
      position: absolute;
      top: 15px;
      left: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .onboarding__back--image {
        transform: rotate(180deg);
        filter: invert(100%) sepia(0%) saturate(6291%) hue-rotate(236deg)
          brightness(117%) contrast(101%);
      }
      .onboarding__back--text {
        text-transform: uppercase;
      }
    }
  }
}
